import { ListClientAccess } from "../../../components/admin/access/ListClientAccess";
import { Footer } from "../../../components/layouts/Footer";
import { Header } from "../../../components/layouts/Header";
import '../../../styles/views/Admin/access/accessStyle.scss';

import {status, User} from '../../../interfaces/admin/access/accessTypes';
import { AccessService } from "../../../services/admin/access/AccessService";
import { useContext, useEffect, useState } from "react";
import Spinner from "../../../components/common/Spinner";
import { AuthContext } from "../../../context/auth/AuthContext";


export const AccessScreen = ()=> {
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [users, setusers] = useState<Array<User>>([])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectStates, setSelectStates] = useState<Array<status>>([])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(true);
  const { authState } = useContext( AuthContext )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getData();
    return()=>{
      setusers([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  
  const getData = async () =>{
    setLoading(true);
    //traer data de los usuarios
    try{
      await AccessService.getAllUsers(authState).then((res)=>{
        if(res.status === 200){
          setusers(res.data.data);
        }
      }).catch((err)=>{
        console.error(err?.response?.data?.status);
      });
    }catch (error){
      console.log(error);
    }
    //traer data de los selectores
    try{
      await AccessService.getAllStateSelect(authState).then((res)=>{
        setSelectStates(res.data.data);
      }).catch((err)=>{
        console.error(err?.response?.data?.status);
      });
    }catch (error){
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div className="container">
        <Header/>
        <div className="body-access">
          <div className="title-admin-access">
              <h4>Users list</h4>
              <p className='line-separator'></p>
          </div>
          {loading ? <div className="admin-access-loading"><Spinner/></div> : <ListClientAccess users={users} selectStates={selectStates} updateData={getData}/> }
        </div>
        <Footer/>
    </div>
  )
}
