//design
import '../../styles/components/layouts/HeaderLogin.scss';
import logoIFS from '../../assets/logos/logoIFS.png';

//header de la pagina ifs limited
export const HeaderLogin = () => {
  return (
    <div className="header_auth">
      <img src={logoIFS} alt="logoIFS" className='image_logo'></img>
    </div>
  )
}
