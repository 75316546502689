// //outer packages
import axios from "axios";
import { AuthState } from "../interfaces/context/authContext/authContextInterface";


//clase para las peticiones al servidor
export class ifsService{
    static baseUrl = process.env.REACT_APP_API_URL

    public static post(path:string, obj:any):Promise<any>{
        return axios.post(this.baseUrl + path, obj);
    }
    public static get(path:string):Promise<any>{
        return axios.get(this.baseUrl + path);
    }
    public static put(path:string, obj:any):Promise<any>{
        return axios.put(this.baseUrl + path, obj);
    }
    public static postH(path:string, obj:any, userAuthenticated: AuthState):Promise<any>{
        return axios.post(this.baseUrl + path, obj,{
            headers: {
              Authorization: userAuthenticated.accessToken
            },
        });
    }
    public static getH(path:string,userAuthenticated: AuthState):Promise<any>{
        return axios.get(this.baseUrl + path,{
            headers: {
              Authorization: userAuthenticated.accessToken
            },
        });
    }
    public static putH(path:string, obj:any, accessToken: string):Promise<any>{
        return axios.put(this.baseUrl + path, obj,{
            headers: {
              Authorization: accessToken
            },
        });
    }
    public static deleteH(path:string, userAuthenticated: AuthState):Promise<any>{
        return axios.delete(this.baseUrl + path,{
            headers: {
              Authorization: userAuthenticated.accessToken
            },
        });
    }
}