import '../../../styles/components/admin/access/ItemClientAccessStyle.scss';
import { SelectPersonalized } from './SelectPersonalized/SelectPersonalized';
import iconEye from '../../../assets/icons/eye-icon.png';
import iconArrow from '../../../assets/icons/arrow-icon.png';
import { useContext, useState } from 'react';
import {status, User} from '../../../interfaces/admin/access/accessTypes'
import { Popup } from '../../common/Popup';
import { AccessService } from '../../../services/admin/access/AccessService';
import { InfoPerson } from './InfoPerson';
import { AuthContext } from '../../../context/auth/AuthContext';
import trashIcon from '../../../assets/icons/trash-icon.png';
import { toast } from 'react-toastify';

interface props{
  user: User,
  //todos los estados que se pueden mostrar
  selectStates : Array<status>,
  updateData: ()=>void
}

export const ItemClientAccess = ({user,selectStates,updateData}:props) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showPoup, setShowPoup] = useState(false);
  const [showPoupDelete, setShowPoupDelete] = useState(false);
  const [newStatusTemp, newSetStatusTemp] = useState<status>(user.status);
  const { authState } = useContext( AuthContext );

  
  let {
    id,
    name,
    status
    } = user;

  //mostrar o no la informacion completa
  const handleChangeShow = ()=>{
    setShowInfo(!showInfo);
  }
  //mostrar popup 
  const handleShowPopupStatus = (status: status)=>{
    setShowPoup(true);
    newSetStatusTemp(status);
  }
  //esconder popup
  const handleHiddenPopup = ()=>{
    setShowPoup(false);   
    setShowPoupDelete(false);   
  }

  //cambiar el estado de la persona
  const handleChangeStatus = async ()=>{
    try{
      await AccessService.updateStatePerson(id,newStatusTemp, authState).then((res)=>{
        if(res.status === 200){
          //refrescar informacion
          updateData();
        }
      }).catch((err)=>{
        console.error(err?.response?.data?.status);
      });
    }catch (error){
      console.log(error);
    }
  }

  const handleShowDeletePerson = () => {
    setShowPoupDelete(true);
  }

  //eliminar a una persona
  const handleDeletePerson = async ()=>{
    try{
      await AccessService.deletePerson(id, authState).then((res)=>{
       toast("User successfully removed");
        //refrescar informacion
        updateData();
      }).catch((err)=>{
        console.error(err?.response?.data?.status);
      });
    }catch (error){
      console.log(error);
    }
    handleHiddenPopup();
  }

  return (
    <li className='item-container'>
      <div className='item-container-option'>
        <div className='item-access'>
          <div className='item-access-onclick' onClick={handleChangeShow}>
            <div>
              <span className='item-name'>{name}</span>
            </div>
            <div className='item-option-icons'>
              <img src={iconEye} alt="eye-icon" className='item-option-icon'/>
              <img src={iconArrow} alt="arrow-icon" className={showInfo ? 'item-option-icon-invert' : 'item-option-icon'} />
            </div>
          </div>
          <div className='div-center'>
            <SelectPersonalized selectStates={selectStates} status={status} popup={true} handleShowPopup={handleShowPopupStatus}/>
          </div>
          <div className='div-center pointer'>
            <img src={trashIcon} alt='trashIcon' onClick={()=> handleShowDeletePerson()}/>
          </div>
        </div>
      </div>
      {showInfo ? 
        <InfoPerson user={user}/>
      :''}
      {showPoup ? 
        <Popup 
              title='Do you really want to change the status?' 
              optionsSelect={true}
              optionSelectAcceptFunction={handleChangeStatus}
              optionSelectDenyFunction={handleHiddenPopup}
          /> 
        : ''}
        {showPoupDelete ? 
        <Popup 
              title='Do you really want to delete this person?' 
              optionsSelect={true}
              optionSelectAcceptFunction={handleDeletePerson}
              optionSelectDenyFunction={handleHiddenPopup}
          /> 
        : ''}
    </li>
  )
}
