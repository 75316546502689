import { Routes, Route } from "react-router-dom";
import { AccessScreen } from '../views/admin/access/AccessScreen';

export const DasboardRoutes = () => {
  return (
    <>
     <Routes>
        <Route path="access" element={<AccessScreen/>} />
    </Routes>
    
    </>
  )
}
