//internal packages
import { SignUpData } from '../../interfaces/auth/authTypes'
import { AuthService } from '../../services/auth/AuthServices';
import { useState } from 'react';

//outer packages
import validator  from "validator";
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from "react-hook-form";

//components
import Spinner from '../common/Spinner';

//design
import  '../../styles/components/auth/SignupFormStyle.scss';
import { Popup } from '../common/Popup';


const SignupForm = () => {
  //estado de mostrar que se esta cargando
  const [loading, setLoading] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  //utilizar el paquete de useForm
  const { register, handleSubmit} = useForm<SignUpData>();
  //validar el for mulario antes de enviar la informacion
  const handleSubmitSignup:SubmitHandler<SignUpData> = data =>{
    //validar inputs
    if( isFormValid(data) ){
      // si es correcto se envia a los endpoint
      handleSendData(data);
    }
  }
  //validar que todo el formulario este correcto
  const isFormValid = (data: SignUpData) : boolean=>{
    if(! validator.isEmail( data.email )){
      toast("The email is incorrect");
      return false;
    }else if(data.name.length < 3){
      toast("The name must be greater than 2 characters");
      return false;
    }else if (! validator.isAlpha(data.name, 'es-ES', {ignore : " '",}
    )){
      toast("The name cannot contain special characters");
      return false;
    }else if(data.investors_name.length <3){
      toast("The Investor's name must be greater than 2 characters");
      return false;
    }else if(!validator.isNumeric(data.phone)){
      toast("The phone number must contain only numbers");
      return false;
    }else if(data.phone.length <7){
      toast("The phone number must contain at least 7 numbers");
      return false;
    }else if(data.phone.length >11){
      toast("The phone number must contain a maximum of 11 numbers");
      return false;
    }
    return true;
  }

  // {
  //   minLength: 4, minLowercase: 0,
  //   minUppercase: 0, minNumbers: 0, minSymbols: 0
  // }


  //mandar los datos a la api y mostrar el resultado
  const handleSendData = async(data:SignUpData)=>{
    handleSetLoading(true);
    try{
      await AuthService.register(data).then((res)=>{
        toast("Successfully registered in");
        showMessage();
      }).catch((err)=>{
        toast(err?.response?.data?.message);
        console.error(err?.response?.data?.status);
      });
    }catch (error){
      toast("System error check your network");
    }
    handleSetLoading(false);
  }

  //cambiar el estado de la carga
  const handleSetLoading = (value: boolean)=>{
    setLoading(value);
  }
  //mostrar el componente de cargando si es necesario
  if (loading) {
    return <Spinner />;
  }
  const showMessage = ()=>{
    setShowPopup(true);
    setTimeout(()=>{
      setShowPopup(false);
  }, 10000);
  }

  return (
    <>
      {/* contenido de la pagina */}
        
        <div className='form_container'>
            <form onSubmit={handleSubmit(handleSubmitSignup)} className="form">
                <label className="label_form">
                  <input 
                    {...register("name")}
                    placeholder="Your Name"
                    type="name"
                  />
                </label>
                <label className="label_form">
                  <input 
                    {...register("investors_name")}
                    placeholder= "Investor's name"
                    type= "text" 
                  />
                </label>
                <label className="label_form">
                  <input 
                    {...register("phone")}
                    placeholder= 'Your phone number'
                    type= "text" 
                  />
                </label>
                <label className="label_form">
                  <input 
                    {...register("email")}
                    placeholder= 'Your email'
                    type= "text" 
                  />
                </label>
                <button className="btn btn_primary"> Create an Account</button>
                <span className='span'>By signing up you agree to Our Terms and Privacy Policy</span>
            </form> 
        </div>
        {showPopup ?  <Popup 
            text='We will send you an email notification when your account is activated.'
            title='Thank you for signing up, your information is being validated by our team.'
            type='good'
          /> :''}
       
    </>
  );
};

export default SignupForm;
