import { User } from "../../../interfaces/admin/access/accessTypes"

interface props{
    user:User
}
//informacion de la persona
export const InfoPerson = ({user}:props) => {
  const {email,name,phone,investors_name} = user;
  return (
    <div className='item-access-info animate__animated animate__fadeIn'>
        <small className='item-access-info-title'>NAME</small>
        <p>{name}</p>
        <small className='item-access-info-title'>INVESTOR'S NAME</small>
        <p>{investors_name}</p>
        <small className='item-access-info-title'>PHONE NUMBER</small>
        <p>{phone}</p>
        <small className='item-access-info-title'>EMAIL</small>
        <p>{email}</p>
    </div>
  )
}
