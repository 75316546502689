//internal packages
import { newPasswordForm, recoveryPassword } from "../../interfaces/auth/authTypes";
import { ApiResponseLogin } from "../../interfaces/response/ApiResponse";
import { SignUpData, SignInData,AuthenticateWithSocialNewtwork } from '../../interfaces/auth/authTypes';
import { ifsService } from "../IfsService";

//servicio de autenticacion
export class AuthService{
    //llevar los datos para comprobar si son correctos para el inicio de sesion
    public static async login(obj:SignInData): Promise<ApiResponseLogin>{
       return ( await ifsService.post('auth/singin', obj) )
    }

    //llevar los datos para crear un nuevo usuario
    public static async register(obj:SignUpData){
       return ( await ifsService.post('auth/singup', obj) ) 
    }

   //llevar los datos para crear un nuevo usuario con google
    public static async AuthenticateWithGoogle(obj:AuthenticateWithSocialNewtwork){
       return ( await ifsService.post('auth/singup-social-network', obj) ) 
    }

   //llevar los datos para recuperar la contrena
   public static async recoveryPassword(obj:recoveryPassword){
      return ( await ifsService.post('auth/recover-password', obj) ) 
   }
   //llevar los datos para recuperar la contrena
   public static async chancePassword(obj:newPasswordForm, accessToken:string ){
      return ( await ifsService.putH('auth/update-password', obj, accessToken) ) 
   }
}