import { createContext } from "react";
import { AuthState } from "../../interfaces/context/authContext/authContextInterface";

export type AuthContextProps = {
    authState: AuthState,
    toggleLogin : (name: string, accessToken: string)=> void,
    toggleLogout : ()=>void
}


export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
