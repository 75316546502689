
//imports
import { useContext, useState } from 'react';
import { AuthService } from '../../services/auth/AuthServices';
import { SignInData, SignInDataResponse }from '../../interfaces/auth/authTypes';
import { useNavigate } from 'react-router-dom';

//outer packages
import { toast } from 'react-toastify';
import validator  from "validator";
import { SubmitHandler, useForm } from "react-hook-form";

//components
import Spinner from '../common/Spinner';

//design
import '../../styles/components/auth/LoginFormStyle.scss';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../context/auth/AuthContext';

const LoginForm = ({ handleChangeView } : any) => {
   //estado de mostrar que se esta cargando
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit} = useForm<SignInData>();

  const { toggleLogin } = useContext( AuthContext );

  const navigate = useNavigate();

  //validar el for mulario antes de enviar la informacion
  const handleSubmitLogin:SubmitHandler<SignInData> = async data =>{
    //validar inputs
    if( isFormValid(data) ){
      // si es correcto se envia a los endpoint
      handleSendData(data);
    }
  }
  //validar que todo el formulario este correcto
  const isFormValid = (data: SignInData) : boolean=>{
    if(!validator.isEmail( data.email )){
      toast("The email is incorrect");
      return false;
    }else if(data.password.length < 5){
      toast("Password should be at least 4 characters and match each other");
      return false;
    }
    return true;
  }

  //mandar los datos a la api y mostrar el resultado
  const handleSendData = async(data:SignInData)=>{
    try{
      handleSetLoading(true);
      await AuthService.login(data).then((res)=>{
       
        handleDefineLogin(res.data);
       
      }).catch((err)=>{
        toast(err?.response?.data?.message);
        console.error(err?.response?.data?.message);
      });
    }catch (error){
      toast("System error check your network");
    }
    handleSetLoading(false);
  }

  const handleDefineLogin = (userResponse: SignInDataResponse)=>{
    toast("Successfully logged in");
    let isAdmin = false;
    //buscar si tiene el rol de administrador
    userResponse.user.roles?.forEach((item)=>{
      if(item.value === "admin"){
        isAdmin = true;
      }
    })
    //redirigir
    if(isAdmin){
      toggleLogin(userResponse.user.name, userResponse.accessToken)
      navigate('/admin/access');
    }
    else{
      window.location.href =`${process.env.REACT_APP_REDIRECT_URL}`;
    }

  }

  const handleSetLoading = (value: boolean)=>{
    setLoading(value);
  }
  //mostrar el componente de cargar cuando se necesite
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {/* contenido de la pagina */}
        
        <div className='form_container'>
            <form  onSubmit={handleSubmit(handleSubmitLogin)} className="form">
                <label className="label_form">
                  <input 
                    {...register("email")}
                    placeholder="Your email"
                    type="email"
                  />
                </label>
                <label className="label_form">
                  <input 
                      {...register("password")}
                      placeholder= 'Your Password'
                      type= "password"
                    />
                </label>
                <p 
                  className='recovery_password'
                  onClick={()=>{handleChangeView(3)}}
                  >Forgot your password?</p>
                <button className="btn btn_primary"> Login</button>
            </form> 
        </div>
    </>
  );
};

export default LoginForm;
