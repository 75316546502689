import { useEffect, useReducer } from "react";
import { AuthState } from "../../interfaces/context/authContext/authContextInterface";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./AuthReducer";

const INITIAL_STATE: AuthState ={
    name: "",
    accessToken: "",
    logged: false 
}

const init = ():AuthState =>{
    let haveToken = localStorage.getItem("user");
    let token = {
        name: "",
        accessToken: "",
        logged: false 
    };
    if(haveToken){
        token = JSON.parse(haveToken);
    }
    return token;
}

interface props{
    children: JSX.Element | JSX.Element[]
}

export const AuthProvider = ({ children }: props)=>{
    const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE, init);

    useEffect(() => {
        if( !authState ) return;
    
        localStorage.setItem('user', JSON.stringify(authState));
      }, [ authState ]);

    const toggleLogin = (name: string, accessToken: string)=>{
        dispatch({type:'[auth] Login', payload: { name,accessToken}})
    }

    const toggleLogout = ()=>{
        dispatch({type:'[auth] Logout', payload: {name: "", accessToken:""}})
    }

    return(
        <AuthContext.Provider value={{
            authState,
            toggleLogin,
            toggleLogout
        }}>
            {children}
        </AuthContext.Provider>
    )
}