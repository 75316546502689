import { AuthState } from "../../interfaces/context/authContext/authContextInterface";
import { AuthTypes } from "../../types/AuthTypes";

export const authReducer = ( state:AuthState, action: AuthTypes ) =>{

    switch (action.type) {
        case '[auth] Login':
            return {
                ...action.payload,
                logged: true
            }
        case '[auth] Logout':
            return {
                ...action.payload,
                logged: false
            }
        default:
            return state
    }
}