//design
import  '../../styles/components/common/PopupStyle.scss';
import checkIcon from '../../assets/icons/check-icon.png';

interface props{
    type?: string,
    title: string,
    text?: string,
    //mostrar las opciones de acepto o cancelo
    optionsSelect?: boolean
    //funcion para los botones aceptar y rechazar
    optionSelectAcceptFunction? : ()=>void,
    optionSelectDenyFunction? : ()=>void
}
export const Popup = ({text,type,title,optionsSelect=false, optionSelectAcceptFunction,optionSelectDenyFunction}:props) => {
  return (
    <div className="popup-container">
        <div className="popup-item animate__animated animate__zoomIn animate__faster">
            {type && <div className='icon-popup-status'>
                <img src={checkIcon} alt='checkIcon'/>
            </div>}
            <div className='title-popup'>
                <span>{title}</span>
            </div>
            <div className='text-popup'>{text}</div>
            {optionsSelect ? 
                <div className='popup-container-options'>
                    <button className='popup-option-btn popup-option-accept' onClick={optionSelectAcceptFunction}>ACCEPT</button>
                    <button className='popup-option-btn popup-option-deny' onClick={optionSelectDenyFunction}>DENY</button>
                </div>
                :
                    ''
            }
        </div>
    </div>
  )
}
