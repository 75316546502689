import { useEffect, useState } from 'react';
import { status } from '../../../../interfaces/admin/access/accessTypes';
import '../../../../styles/components/admin/access/SelectPersonalizedStyle.scss';
import { SelectItem } from './SelectItem';

interface props{
    selectStates : Array<status>
    status : status
    popup?: boolean
    handleShowPopup?: (status:status)=>void,
}
export const SelectPersonalized = ({selectStates, status,popup=false, handleShowPopup=()=>{}}:props) => {
  const [showList, setShowList] = useState(false);
  // const [selected, setSelected] = useState(status);
  const [classNameSelected, setClassNameSelected] = useState('');

  //mostrar lista con las opciones de selectores
  const handleChangeShow = ()=>{
    setShowList(!showList);
  }
  //definir el color del select seleccionado
  useEffect(() => {
    if(status.value === "declined"){
      setClassNameSelected ("item-selected select-red");
    }else if(status.value === "enable"){
      setClassNameSelected("item-selected select-green");
    }else if(status.value === "inactive"){
      setClassNameSelected("item-selected select-yellow");         
    }else if(status.value === "pending"){
      setClassNameSelected("item-selected select-gray");         
    }
  }, [status.value])
  

  //cambiar el estado del estado seleccionado
  const handleChangeSelect = (newStatus: status)=>{
    // setSelected(newStatus);
    //mostrar popup si es el caso
    if(popup === true){
      handleShowPopup(newStatus);
    }
  }

  return (
      <div className='box-select'>
          <span className={classNameSelected} onClick={handleChangeShow}>{status.value}</span>
          {showList ? 
              <ul className="item-select-list animate__animated animate__fadeIn animate__faster">
                  {selectStates.map((selectState)=>{
                     return <SelectItem 
                        key={selectState.id}
                        selectState={selectState}
                        changeSelect={handleChangeSelect}
                        handleChangeShow={handleChangeShow}
                      />
                  })} 
              </ul>
          : 
              ""
          }
      </div>
  )
}
