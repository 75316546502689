import { ItemClientAccess } from "./ItemClientAccess"
import '../../../styles/components/admin/access/ListClientAccessStyle.scss';
import {status, User} from '../../../interfaces/admin/access/accessTypes'
interface props {
  users : Array<User>,
  selectStates : Array<status>,
  updateData : ()=> void
}

export const ListClientAccess = ({users,selectStates,updateData}:props) => {
  return (
    <div>
        <ul className="list-access-container">
          {users.map((user)=>{
              return <ItemClientAccess 
              key={user.id} 
              user={user} 
              selectStates={selectStates} 
              updateData={updateData}
            />
          })}
            
        </ul>
    </div>
  )
}
