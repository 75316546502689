import { status } from "../../../../interfaces/admin/access/accessTypes";

interface props{
    selectState : status
    changeSelect : (newStatus: status)=>void
    handleChangeShow : ()=>void
}


export const SelectItem = ({selectState,changeSelect,handleChangeShow}:props) => {

    const {value} = selectState;
    let className = ""
    //definir color de; select
    if(value === "declined"){
        className= "select-red";
    }else if(value === "enable"){
        className="select-green";
    }else if(value === "inactive"){
        className="select-yellow";         
    }else if(value === "pending"){
        className="select-gray";         
    }
    return (
        <li 
            className={className}
            onClick={()=>{
                //cambiar estado
                changeSelect(selectState);
                //ocultar la lista de elementos desplegables
                handleChangeShow();
            }}
            >
            <span>{value}</span>
        </li>
         
    )
}
